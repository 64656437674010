import React from 'react'

function streamCreate() {
    return (
        <div>
            streamCreate
        </div>
    )
}

export default streamCreate
