import React, { Component } from 'react';
import { connect } from 'react-redux';
import {signIn,signOut} from '../actions';
import { Spinner } from 'reactstrap';

//auth.currentUser.get().getBasicProfile().getName()
class GoogleAuth extends Component {
    componentDidMount() {
        window.gapi.load('client:auth2',()=>{
            window.gapi.client
            .init({
                clientId:
                 '763514237361-v7hru1loaucrpuip59pmnielgiuej7e9.apps.googleusercontent.com',
                scope:'email'
            })
            .then(() => {
               
               this.auth=window.gapi.auth2.getAuthInstance();
               this.onAuthChange(this.auth.isSignedIn.get());
               console.log(this.auth.isSignedIn.get())
               this.auth.isSignedIn.listen(this.onAuthChange);
            });
        });
    }
            // console.log(this.auth.currentUser.get().getBasicProfile())

    ///this.auth.currentUser.get().getId()
    onAuthChange= isSignedIn =>{
        if (isSignedIn)
        this.props.signIn(this.auth.currentUser.get().getBasicProfile());
            // console.log(this.auth.currentUser.get().getId());
        
        else{this.props.signOut()
        }
    }
    onSignIn=()=>{
    this.auth.signIn()
    }
    onSignOut=()=>{
    this.auth.signOut()
    }
    renderAuthButton(){
        if(this.props.isSignedIn===null){
            return (
                <Spinner color="danger" />
            )
        }
        else if(this.props.isSignedIn){
            return (
                <button key="btnSignIn" type="button" onClick={this.onSignOut} className="btn rounded btn-outline-danger btn-tooltips border-drawing ">
                      <span>{this.props.userProfile.getName()}</span>
                      </button>
            )
        }
        else{
            return (
                <button key="btnSignOut" type="button" onClick={this.onSignIn}  className="btn rounded btn-danger">
                   <i className="fab fa-google"></i>  Sign In With Google</button>
            )
        }
    }

    render() {
        return (
                this.renderAuthButton()
        )
    }
    
}
const mapStateToProps = state => {
    return {
    isSignedIn:state.auth.isSignedIn,
    userProfile:state.auth.userProfile
};};

export default connect(
    mapStateToProps,
    {signIn,signOut}
    )(GoogleAuth)