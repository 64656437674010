import React from 'react'

function streamDelete() {
    return (
        <div>
            streamDelete
        </div>
    )
}

export default streamDelete
