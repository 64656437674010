import React from 'react';
import {Link} from 'react-router-dom';
import GoogleAuth from './GoogleAuth'

import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
 } from 'reactstrap';

export default class Header extends React.Component{
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    return (
      <div className="row">
        <Navbar color="dark" dark expand="md" className="bg-primary p-2 w-100">
          <Link to="/" className="navbar-brand">Vido</Link>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="w-100 align-items-center" navbar>
              <NavItem className="">
                <NavLink href="/components/">Components</NavLink>
              </NavItem>
              <NavItem className="ml-md-auto">
                <GoogleAuth/>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}