import React from 'react';
import '../css/bootstrap.min.css'
import '../css/main.css'
import { BrowserRouter , Route} from 'react-router-dom';
import streamShow from './stream/streamShow';
import streamDelete from './stream/streamDelete';
import streamEdit from './stream/streamEdit';
import streamList from './stream/streamList';
import streamCreate from './stream/streamCreate';
import Header from './Header'


const App= ()=>{
    return(<div className="container-fluid">
        <BrowserRouter>
            <div>
                <Header/>
                <Route path="/" exact component={streamList}/>
                <Route path="/streams/new" exact component={streamCreate}/>
                <Route path="/streams/delete" exact component={streamDelete}/>
                <Route path="/streams/edit" exact component={streamEdit}/>
                <Route path="/streams/show" exact component={streamShow}/>
            </div>
        </BrowserRouter></div>
         );
};
export default App;